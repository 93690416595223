<template>
  <main class="container">
    <BaseHeader :title="$t('views.create_product.header_title')"></BaseHeader>
    <router-view></router-view>
  </main>
</template>

<script>
export default {
  name: "CreateProduct",
  computed: {
    hasRouterType() {
      return this.$route.params.type || null;
    },
    types() {
      return this.$store.state.createProduct.types || [];
    },
    countryCode() {
      return localStorage.getItem("locationadm") || "BR";
    },
  },
  methods: {
    async init() {
      await this.$store.commit(
        "createProduct/setCountryCode",
        this.countryCode
      );
      this.$store
        .dispatch("createProduct/fetchTypes")
        .then(() => {
          if (this.hasRouterType) {
            this.$store.commit(
              "createProduct/setCurrentType",
              this.types.find((type) => type.type === this.hasRouterType)
            );
            this.$store.commit("createProduct/resolveCurrentChecklist");
          }
        })
        .catch(() => {
          this.$router.push("/produtos");
        });
    },
  },
  watch: {
    $route() {
      this.init();
    },
  },
  mounted() {
    this.init();
  }
}
</script>
